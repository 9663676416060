/*
* Theme: Bootadmin Library
*/

/** Libraries **/
@import "bootstrap.min.css";
@import "animate.css";
@import "animsition.min.css";
@import "waves.min.css";
@import "dropzone.css";
@import "feathericons.css";
@import "flickity.min.css";
